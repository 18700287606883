<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div>
    <!-- Modal para agregar afiliado -->
    <v-dialog v-model="formAfiliado.visible" persistent transition="dialog-bottom-transition" max-width="850">
      <v-card>
        <v-toolbar color="primary" class="text-h6" dark>{{ formTitle }}</v-toolbar>
        <v-card-text>

          <!-- formulario para agredar Afiliado -->
          <validation-observer v-slot="{ invalid }" ref="observer">
            <v-form ref="formAfiliado" class="mt-3" v-model="formValidaAfiliado">
              <div class="d-flex mt-3">
                <validation-provider style="width: 25%" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete v-model="formNewAfil.tipoDocumento" :items="tiposDoc" label="Tipo de documento"
                    :error-messages="errors" class="me-3" :readonly="formAfiliado.edit"
                    @input="validarExistenciaAfiliadoDebounced()" dense outlined>
                  </v-autocomplete>
                </validation-provider>
                <validation-provider style="width: 25%" v-slot="{ errors }" :rules="{ required: true }">
                  <v-text-field v-model="formNewAfil.numeroDocumento" :counter="11" class="me-6"
                    :error-messages="errorAfiliado || errors" label="N° de documento" :readonly="formAfiliado.edit"
                    @input="validarExistenciaAfiliadoDebounced()" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }"
                  :rules="{ required: true, charactersRule: true }">
                  <v-text-field v-model="formNewAfil.primerNombre" :error-messages="errors" class="me-3"
                    label="Primer nombre" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ charactersRule: true }">
                  <v-text-field v-model="formNewAfil.segundoNombre" :error-messages="errors" label="Segundo nombre"
                    dense outlined>
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex">
                <validation-provider style="width: 50%;" v-slot="{ errors }"
                  :rules="{ required: true, charactersRule: true }">
                  <v-text-field v-model="formNewAfil.primerApellido" class="me-6" :error-messages="errors"
                    label="Primer apellido" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 50%;" v-slot="{ errors }" :rules="{ charactersRule: true }">
                  <v-text-field v-model="formNewAfil.segundoApellido" label="Segundo apellido" :error-messages="errors"
                    dense outlined>
                  </v-text-field>
                </validation-provider>
              </div>
              <div class="d-flex">
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-select v-model="formNewAfil.sexo" :items="tiposSexo" label="Sexo" :error-messages="errors"
                    class="me-3" dense outlined>
                  </v-select>
                </validation-provider>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                      <v-text-field v-model="formNewAfil.fechaNacimiento" label="Fecha de nacimiento" class="me-6"
                        :error-messages="errors" readonly v-bind="attrs" v-on="on" dense outlined>
                      </v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker v-model="formNewAfil.fechaNacimiento" :active-picker.sync="activePicker"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="saveDate"></v-date-picker>
                </v-menu>
                <validation-provider style="width: 25%;" v-slot="{ errors }" name="celular" :rules="{
      required: formNewAfil.numeroCelular == '' && formNewAfil.numeroTelefono == '' ? true : false,
      digits: 10,
      regex: '^(3)\\d{9}$'
    }">
                  <v-text-field v-model="formNewAfil.numeroCelular" class="me-3" :error-messages="errors" :counter="10"
                    label="Celular" dense outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" name="telefono" :rules="{
      required: formNewAfil.numeroCelular == '' && formNewAfil.numeroTelefono == '' ? true : false,
      digits: 10,
      regex: '^(601|602|604|605|606|607|608)\\d{7}$'
    }">
                  <v-text-field v-model="formNewAfil.numeroTelefono" :error-messages="errors" :counter="10"
                    label="Teléfono" dense outlined>
                  </v-text-field>
                </validation-provider>
              </div>

              <div class="d-flex">
                <validation-provider v-slot="{ errors }" style="width: 51%;" name="email" rules="email">
                  <v-text-field v-model="formNewAfil.email" class="me-6" :error-messages="errors" label="Email" dense
                    outlined>
                  </v-text-field>
                </validation-provider>
                <validation-provider style="width: 25%;" class="me-3" v-slot="{ errors }" :rules="{ required: true }">
                  <v-select v-model="formNewAfil.regimen" :error-messages="errors" :items="regimenAfiliados"
                    label="Régimen afiliado" dense outlined>
                  </v-select>
                </validation-provider>
                <validation-provider style="width: 25%;" v-slot="{ errors }" :rules="{ required: true }">
                  <v-autocomplete v-model="formNewAfil.nivel" :items="nivelAfiliados" label="Nivel afiliado"
                    :error-messages="errors" dense outlined>
                  </v-autocomplete>
                </validation-provider>
              </div>
              <div class="d-flex">
                <validation-provider v-slot="{ errors }" style="width: 50%;" rules="required|min:3">
                  <v-text-field v-model="formNewAfil.direccion" :error-messages="errors"
                    @click="dialogTipoDireccion = true" class="me-6" label="Dirección" readonly dense outlined>
                  </v-text-field>
                </validation-provider>

                <validation-provider style="width: 50%;" v-slot="{ errors }" v-if="formAfiliado.edit"
                  :rules="{ required: true }">
                  <v-autocomplete v-model="formNewAfil.estadoAfiliado" :items="itemsEstradoAfiliado" dense
                    :error-messages="errors" label="Estado Afiliado" outlined></v-autocomplete>
                </validation-provider>

                <validation-provider style="width: 50%;" v-slot="{ errors }" v-if="!formAfiliado.edit"
                  :rules="{ required: true }">
                  <v-autocomplete v-model="formNewAfil.codigoAseguradora" :items="aseguradoraItems" dense
                    :error-messages="errors" label="Aseguradora" outlined></v-autocomplete>
                </validation-provider>
              </div>

              <div class="d-flex justify-end">
                <v-btn text color="error" @click="cerrarAgregarAfiliado()">Cerrar</v-btn>
                <v-btn depressed :disabled="invalid"
                  @click="accionBotonGuardarAfiliado(formAfiliado.afiliadoSeleccionado)" color="success">
                  Guardar </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
      <v-overlay :value="overlayAfiliadoForm">
        <v-progress-circular :size="70" color="primary" indeterminate></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <!-- modal para validad tipo de direccion -->
    <v-dialog v-model="dialogTipoDireccion" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" class="text-h6" dark>Dirección</v-toolbar>

        <v-form ref="formMunicipio" v-model="formValidaMunicipio">
          <v-radio-group v-model="formNewAfil.tiposDireccion" class="ms-3" :rules="rules.requiredRule" row>
            <v-radio label="Urbana" @click="tipoDireccionUrbana()" value="U"></v-radio>
            <v-radio label="Rural" @click="direccionRural = true, direccionUrbana = false" value="R"></v-radio>
          </v-radio-group>
          <div class="d-flex mx-3">
            <v-autocomplete v-model="formNewAfil.codigoDepartamento" class="me-2" :items="departamentos" dense outlined
              label="Departamento" :rules="rules.requiredRule"></v-autocomplete>
            <v-autocomplete v-model="formNewAfil.codigoMunicipio" :items="itemsCiudad" dense outlined label="Ciudad"
              :rules="rules.requiredRule"></v-autocomplete>
          </div>
        </v-form>

        <!-- form para direccion urbana -->
        <v-form v-show="direccionUrbana" ref="formDirUrbana">
          <v-container>
            <div class="d-flex">
              <v-autocomplete v-model="tipoVia" :items="tiposVias" class="me-4" label="Tipo de via"
                :rules="rules.requiredRule">
              </v-autocomplete>
              <v-text-field v-model="numVia" @change="verNumDireccion = true" label="Ej. 32C" class="me-4"
                :rules="rules.direccionRule"></v-text-field>
              <v-text-field v-model="letraNum" class="me-4" label="Ej. 45" :rules="rules.direccionRule"></v-text-field>
              <v-text-field v-model="cuadrante" label="Ej. 21" :rules="rules.direccionRule"></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field v-model="pisoApartamento" @change="verPiso = true" label="Piso o Apartamento (ej. 2A)"
                class="me-4">
              </v-text-field>
              <v-autocomplete v-model="formNewAfil.barrio" label="Barrios" :items="barriosMedellin"></v-autocomplete>
            </div>
            <v-alert color="green lighten-2" type="success" v-show="tipoVia != null ? true : false">
              <span>
                {{ tipoVia }} {{ numVia }} <span v-show="verNumDireccion">#</span>
                {{ letraNum }} - {{ cuadrante }}
                <span v-show="verPiso" style="font-weight: bold;">Piso o Apartamento: </span>
                {{ pisoApartamento }}
                <!-- <span v-show="verBarrio" style="font-weight: bold;">Barrio: </span>
                {{ barrio }} -->
              </span>
            </v-alert>

            <v-card-actions class="d-flex justify-end">
              <v-btn text color="error" @click="dialogTipoDireccion = false">Cerrar</v-btn>
              <v-btn color="success" @click="saveDireccionUrbana()"> Aceptar </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>

        <v-form v-show="direccionRural" ref="formDirRural">
          <v-container>
            <div class="d-flex">
              <v-select v-model="tipoVereda" :items="['Vereda', 'Sector']" class="me-4" label="Seleccione..."
                :rules="rules.requiredRule">
              </v-select>
              <v-text-field v-model="nombreVereda" label="Nombre" class="me-4"
                :rules="rules.requiredRule"></v-text-field>
            </div>
            <v-alert color="green lighten-2" type="success" v-show="tipoVereda != null ? true : false">
              <span>
                {{ tipoVereda }} {{ nombreVereda }}
              </span>
            </v-alert>
          </v-container>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="dialogTipoDireccion = false">Cerrar</v-btn>
            <v-btn color="success" @click="saveDireccionRural()"> Aceptar </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { required, regex, digits, min, email } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

setInteractionMode('eager')
extend('required', {
  ...required,
  message: 'Este campo es requerido.',
});
extend('min', {
  ...min,
  message: 'Dirección erronea',
});

extend('digits', {
  ...digits,
  message: 'Número incorrecto.',
});

extend('regex', {
  ...regex,
  message: 'Formato incorrecto',
});

extend('email', {
  ...email,
  message: 'Formato de email incorrecto.',
})

extend('charactersRule', {
  validate: (v) => {
    if (v === '') return true
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d10-9]+$/;
    if (!regex.test(v)) return "Carácter no aceptado.";
    return true;
  },
  message: 'Carácter no aceptado.',
});


export default {
  name: 'AfiliadoForm',
  props: {
    tiposDoc: Array,
    editarAfiliado: Function,
    aseguradoraItems: Array,
    departamentos: Array,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      formValidaAfiliado: true,
      formValidaMunicipio: true,
      overlayAfiliadoForm: false,
      menu: false,
      activePicker: null,
      errorAfiliado: '',
      formNewAfil: {
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        sexo: '',
        tipoDocumento: null,
        numeroDocumento: null,
        fechaNacimiento: null,
        codigoPais: '170',
        codigoDepartamento: '',
        codigoMunicipio: '',
        email: '',
        actualizoUsuario: '',
        numeroTelefono: '',
        numeroCelular: '',
        codigoAseguradora: '',
        regimen: '',
        estadoAfiliado: '',
        nivel: '',
        tiposDireccion: null,
        direccion: '',
        barrio: null,
      },
      barriosMedellin: [],
      nivelAfiliados: [{ text: 1, value: 'LOW_LOW' }, { text: 2, value: 'LOW' }, { text: 3, value: 'MEDIUM_LOW' }, { text: 4, value: 'MEDIUM' }, { text: 5, value: 'MEDIUM_HIGH' }, { text: 6, value: 'HIGH' }],
      itemsCiudad: [],
      tipoVia: null,
      tipoVereda: null,
      nombreVereda: '',
      numVia: '',
      letraNum: '',
      cuadrante: '',
      pisoApartamento: '',
      regimenAfiliados: [{ text: 'Contributivo', value: 'CONTRIBUTIVO' }, { text: 'Subsidiado', value: 'SUBSIDIADO' }, { text: 'Especial', value: 'ESPECIAL' }, { text: 'Excepción', value: 'EXCEPCION' }, { text: 'Militar', value: 'MILITAR' }, { text: 'Otro régimen', value: 'OTRO_REGIMEN' }, { text: 'Sin régimen', value: 'SIN_REGIMEN' }],
      itemsEstradoAfiliado: [{ text: 'Activo', value: 'ACTIVO' }, { text: 'Inactivo', value: 'INACTIVO' }, { text: 'Suspendido', value: 'SUSPENDIDO' }, { text: 'Retirado', value: 'RETIRADO' }, { text: 'Fallecido', value: 'FALLECIDO' }, { text: 'Pendiente', value: 'PENDIENTE' },],
      tiposSexo: [{ text: "Femenino", value: "FEMALE" }, { text: "Masculino", value: "MALE" }, { text: "Indeterminado", value: "INDETERMINATE" }],
      tiposVias: ["Avenida calle", "Avenida carrera", "Autopista", "Avenida", "Anillo vial", "Calle", "Callejón", "Carrera", "Carretera", "Circunvalar", "Diagonal", "Transversal"],
      rules: {
        requiredRule: [
          v => !!v || "Este campo es requerido",
        ],
        direccionRule: [
          v => !!v || "Este campo es requerido",
          v => {
            const regex = /^[a-zA-Z0-9]+$/;
            const firstChar = v?.charAt(0);
            if (!regex.test(v)) {
              return "Carácter no aceptado.";
            } else if (firstChar === "0") {
              return "Formato incorrecto.";
            }
            return true;
          }
        ]
      },
      dialogTipoDireccion: false,
      verPiso: false,
      verBarrio: false,
      verNumDireccion: false,
      direccionUrbana: false,
      direccionRural: false,
    }
  },

  watch: {
    menu(val) { val && setTimeout(() => (this.activePicker = 'YEAR')) },
    "formNewAfil.codigoDepartamento": function () { this.municipios(); },
    "formAfiliado.edit": function () { this.datosEditar() },
    "formNewAfil.codigoMunicipio": function () { this.listBarrios() },
  },

  created() {
    this.formAfiliado.visible = false;
    this.formAfiliado.edit = false;
    this.formAfiliado.afiliadoSeleccionado = {};
    this.formNewAfil.actualizoUsuario = this.auth.username.toUpperCase();
  },

  computed: {
    ...mapState(["auth", "notify", "formAfiliado", "enterprise"]),
    formTitle() {
      return this.formAfiliado.edit === true ? "Editar afiliado" : "Crear afiliado";
    },
  },

  methods: {
    ...mapMutations(["showSuccess", "showError", "saveAfil", "editAfil"]),

    validarExistenciaAfiliado() {
      if (this.formNewAfil.tipoDocumento && this.formNewAfil.numeroDocumento) {
        this.$http.get(`msa-pharmaceutical-services/api/Afiliado/validar-existencia`, {
          params: {
            tipoDocumento: this.formNewAfil.tipoDocumento,
            numeroDocumento: this.formNewAfil.numeroDocumento?.trim(),
            empresa: this.enterprise.code
          }
        }).then(({ data }) => {
          if (data) this.errorAfiliado = "El afiliado ya existe";
          if (!data) this.errorAfiliado = "";
        }).catch((error) => console.log(error));
      }
    },

    validarExistenciaAfiliadoDebounced: debounce(function () {
      this.validarExistenciaAfiliado();
    }, 500),

    saveDate(date) {
      this.$refs.menu.save(date)
    },

    cerrarAgregarAfiliado() {
      this.formAfiliado.visible = false;
      this.formAfiliado.edit = false;
      this.direccionUrbana = false;
      this.direccionRural = false;
      this.clear();
    },

    accionBotonGuardarAfiliado(dataAfiliado) {
      if (this.$refs.formAfiliado.validate()) {
        if (this.formAfiliado.edit) {
          this.editar(dataAfiliado.idAfiliado);
        } else {
          this.guardar()
        }
      } else { this.showError("Complete todos los campos") }

    },

    guardar() {
      this.overlayAfiliadoForm = true;
      const nombreCompleto = `${this.formNewAfil.primerNombre} ${this.formNewAfil.segundoNombre} ${this.formNewAfil.primerApellido} ${this.formNewAfil.segundoApellido}`;
      let { primerNombre, segundoNombre, primerApellido, segundoApellido, sexo, tipoDocumento, numeroDocumento, fechaNacimiento, codigoPais, codigoAseguradora, codigoDepartamento, codigoMunicipio, email, numeroTelefono, numeroCelular, regimen, nivel, direccion } = this.formNewAfil;
      const barrio = this.formNewAfil?.barrio == null ? { idBarrio: '00000000' } : { idBarrio: this.formNewAfil.barrio };
      if (numeroTelefono === '') numeroTelefono = 0;
      if (numeroCelular === '') numeroCelular = 0;
      this.$http.post(`msa-pharmaceutical-services/api/Afiliado/save/${codigoAseguradora}`, {
        empresa: { idEmpresa: this.enterprise.code },
        tipoDocumento: { tipoDocumento: tipoDocumento.trim(), },
        numeroDocumento: numeroDocumento.trim().toUpperCase(),
        nombreCompleto: nombreCompleto.toUpperCase(),
        primerNombre: primerNombre.toUpperCase(),
        segundoNombre: segundoNombre?.toUpperCase(),
        primerApellido: primerApellido.toUpperCase(),
        segundoApellido: segundoApellido?.toUpperCase(),
        genero: sexo,
        tipoDireccion: this.formNewAfil.tiposDireccion,
        direccion: direccion,
        numeroTelefono: numeroTelefono,
        email: email,
        numeroCelular: numeroCelular,
        nivel: nivel,
        estadoAfiliado: "ACTIVO",
        fechaNacimiento: `${fechaNacimiento}T00:00:00-05:00`,
        regimen: regimen,
        pais: { idPais: codigoPais },
        departamento: { idDepartamento: codigoDepartamento },
        ciudad: { idCiudad: codigoMunicipio },
        barrio
      }).then(({ data }) => {
        this.cerrarAgregarAfiliado();
        this.showSuccess(data);
        this.overlayAfiliadoForm = false;
        this.$emit('getAfiliados');
      }).catch(err => {
        this.showError(err.response.data)
        this.overlayAfiliadoForm = false;
      });
    },

    editar(id) {
      this.overlayAfiliadoForm = true;
      const nombreCompleto = `${this.formNewAfil.primerNombre} ${this.formNewAfil.segundoNombre} ${this.formNewAfil.primerApellido} ${this.formNewAfil.segundoApellido}`;
      let { primerNombre, segundoNombre, primerApellido, segundoApellido, sexo, tipoDocumento, numeroDocumento, fechaNacimiento, codigoPais, codigoDepartamento, codigoMunicipio, email, numeroTelefono, numeroCelular, regimen, estadoAfiliado, nivel, direccion } = this.formNewAfil;
      const barrio = this.formNewAfil?.barrio == null ? { idBarrio: '00000000' } : { idBarrio: this.formNewAfil.barrio };
      if (numeroTelefono === '') numeroTelefono = 0;
      if (numeroCelular === '') numeroCelular = 0;
      this.$http.post(`msa-pharmaceutical-services/api/Afiliado/edit`, {
        idAfiliado: id,
        empresa: { idEmpresa: this.enterprise.code },
        tipoDocumento: { tipoDocumento: tipoDocumento.trim(), },
        numeroDocumento: numeroDocumento.trim().toUpperCase(),
        nombreCompleto: nombreCompleto.toUpperCase(),
        primerNombre: primerNombre.toUpperCase(),
        segundoNombre: segundoNombre?.toUpperCase(),
        primerApellido: primerApellido.toUpperCase(),
        segundoApellido: segundoApellido?.toUpperCase(),
        genero: sexo,
        tipoDireccion: this.formNewAfil.tiposDireccion,
        direccion: direccion,
        numeroTelefono: numeroTelefono,
        email: email,
        numeroCelular: numeroCelular,
        nivel: nivel,
        fechaNacimiento: `${fechaNacimiento}T00:00:00-05:00`,
        estadoAfiliado: estadoAfiliado,
        regimen: regimen,
        pais: { idPais: codigoPais },
        departamento: { idDepartamento: codigoDepartamento },
        ciudad: { idCiudad: codigoMunicipio },
        barrio,
        lastmodifiedby: this.formNewAfil.actualizoUsuario,
      }).then(() => {
        this.cerrarAgregarAfiliado();
        this.showSuccess('El afiliado ha sido actualizado correctamente.');
        this.$emit('getAfiliados');
        this.overlayAfiliadoForm = false;
      }).catch(err => {
        console.log(err)
        this.overlayAfiliadoForm = false;
      });
    },

    municipios() {
      this.itemsCiudad = [];
      this.$http.get(`msa-administration/municipio/listaDeMunicipios`, {
        params: { idDepartamento: this.formNewAfil.codigoDepartamento }
      }).then(({ data }) => {
        if (data) data.forEach(municipio => this.itemsCiudad.push({ text: municipio.ciudad, value: municipio.idCiudad }));
      }).catch(err => console.log(err))
    },

    saveDireccionUrbana() {
      this.formNewAfil.direccion = '';
      if (this.$refs.formDirUrbana.validate() && this.$refs.formMunicipio.validate()) {
        const dir = `${this.tipoVia} ${this.numVia != '' ? this.numVia + " #" : ''}${this.letraNum != '' ? this.letraNum + " -" : ''} ${this.cuadrante} ${this.pisoApartamento}`;
        this.formNewAfil.direccion = dir;
        this.dialogTipoDireccion = false;
        this.$refs.formDirRural?.reset();
      } else {
        this.showError('Complete los campos requeridos');
      }
    },

    saveDireccionRural() {
      this.formNewAfil.direccion = '';
      if (this.$refs.formDirRural.validate() && this.$refs.formMunicipio.validate()) {
        const dir = `${this.tipoVereda} ${this.nombreVereda}`;
        this.formNewAfil.direccion = dir;
        this.dialogTipoDireccion = false;
        this.$refs.formDirUrbana?.reset();
      } else {
        this.showError('Complete los campos requeridos');
      }
    },

    datosEditar() {
      if (this.formAfiliado.edit) {
        this.formNewAfil.primerNombre = this.formAfiliado.afiliadoSeleccionado.primerNombre;
        this.formNewAfil.segundoNombre = this.formAfiliado.afiliadoSeleccionado.segundoNombre;
        this.formNewAfil.primerApellido = this.formAfiliado.afiliadoSeleccionado.primerApellido;
        this.formNewAfil.segundoApellido = this.formAfiliado.afiliadoSeleccionado.segundoApellido;
        this.formNewAfil.sexo = this.formAfiliado.afiliadoSeleccionado.genero;
        this.formNewAfil.tipoDocumento = this.formAfiliado.afiliadoSeleccionado.tipoDocumento.tipoDocumento.trim();
        this.formNewAfil.numeroDocumento = this.formAfiliado.afiliadoSeleccionado.numeroDocumento.trim();
        this.formNewAfil.fechaNacimiento = this.formAfiliado.afiliadoSeleccionado.fechaNacimiento;
        this.formNewAfil.codigoDepartamento = this.formAfiliado.afiliadoSeleccionado.departamento?.idDepartamento.trim();
        this.formNewAfil.codigoMunicipio = this.formAfiliado.afiliadoSeleccionado.ciudad?.idCiudad;
        this.formNewAfil.email = this.formAfiliado.afiliadoSeleccionado.email;
        if (this.formAfiliado.afiliadoSeleccionado.numeroCelular === 0) this.formAfiliado.afiliadoSeleccionado.numeroCelular = ''
        this.formNewAfil.numeroCelular = this.formAfiliado.afiliadoSeleccionado.numeroCelular;
        this.formNewAfil.regimen = this.formAfiliado.afiliadoSeleccionado.regimen;
        this.formNewAfil.estadoAfiliado = this.formAfiliado.afiliadoSeleccionado.estadoAfiliado;
        this.formNewAfil.nivel = this.formAfiliado.afiliadoSeleccionado.nivel;
        this.formNewAfil.direccion = this.formAfiliado.afiliadoSeleccionado.direccion;
        if (this.formAfiliado.afiliadoSeleccionado.numeroTelefono === 0) this.formAfiliado.afiliadoSeleccionado.numeroTelefono = ''
        this.formNewAfil.numeroTelefono = this.formAfiliado.afiliadoSeleccionado.numeroTelefono;
        this.formNewAfil.tiposDireccion = this.formAfiliado.afiliadoSeleccionado.tipoDireccion;
        if (this.formNewAfil.tiposDireccion == "U") this.direccionUrbana = true;
        if (this.formNewAfil.tiposDireccion == "R") this.direccionRural = true;
        this.formNewAfil.barrio = this.formAfiliado.afiliadoSeleccionado.barrio != null ? this.formAfiliado.afiliadoSeleccionado.barrio.idBarrio : null;
        setTimeout(() => { this.$refs.observer.validate(); }, 500);
      }
    },

    tipoDireccionUrbana() {
      this.direccionUrbana = true;
      this.direccionRural = false;
    },

    listBarrios() {
      this.barriosMedellin = []
      this.$http.get(`msa-pharmaceutical-services/api/Barrio/list`,
        { params: { ciudad: this.formNewAfil.codigoMunicipio } })
        .then(({ data }) => {
          data.forEach(barrio => this.barriosMedellin.push({ text: barrio.barrio, value: barrio.idBarrio }))
        }).catch(err => console.log(err))
    },

    cerrarDireccionUrbana() {
      this.nombreVereda = '';
      this.formNewAfil.barrio = '';
      this.$refs.formDirUrbana?.reset();
      this.pisoApartamento = '';
      this.$refs.formMunicipio?.reset();
    },

    cerrarDireccionRural() {
      this.nombreVereda = '';
      this.$refs.formDirRural?.reset();
      this.$refs.formMunicipio?.reset();
    },

    clear() {
      this.errorAfiliado = "";
      this.$refs.observer.reset();
      this.$refs.formAfiliado.reset();
      this.formNewAfil.tiposDireccion = null;
      this.verDireccion = false;
      this.verBarrio = false;
      this.cerrarDireccionUrbana();
      this.cerrarDireccionRural();
      this.formNewAfil.numeroCelular = '';
      this.formNewAfil.numeroTelefono = '';
      this.formNewAfil.segundoApellido = '';
      this.formNewAfil.segundoNombre = '';
      this.formNewAfil.email = '';
    },
  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped></style>