<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Febrero 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <div class="d-flex ma-2">
      <v-autocomplete :items="tiposDoc" v-model="tipoDocumento" clearable label="Tipo de documento" class="me-2" dense
        outlined>
      </v-autocomplete>
      <v-text-field v-model="numDocumento" clearable :counter="11" label="N° de documento" dense outlined>
      </v-text-field>
      <v-tooltip :open-on-focus="false" left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-4" fab color="success" @mousedown.prevent="addAfil()" v-bind="attrs" v-on="on">
            <v-icon dark> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar afiliado</span>
      </v-tooltip>

      <AfiliadoForm :tiposDoc="tiposDoc" :aseguradoraItems="aseguradoraItems" :departamentos="departamentos"
        @getAfiliados="getAfiliados" />
    </div>

    <!-- tabla donde muestra busqueda de afiliado -->
    <v-data-table class="tablaAfiliados mx-3" height="59vh" :headers="headers" :loading="cargandoDatos"
      :server-items-length="totalElementos" :page="pagina" :items-per-page="itemsPorPagina" :items="afiliados"
      @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p" :footer-props="footerProps"
      fixed-header>

      <!-- agrega chip de estado de cada uno de los afiliados -->
      <template v-slot:[`item.estadoAfiliado`]="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.estadoAfiliado === 'ACTIVO' ? 'success' : 'error'">
            {{ estadosAfiliado(item.estadoAfiliado) }}
          </v-icon>
          <span>{{ item.estadoAfiliado }}</span>
        </div>
      </template>

      <!-- agrega los botones de acciones a todas las filas -->
      <template v-slot:[`item.acciones`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom color="orange">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon color="orange" v-bind="attrs" v-on="on" @click="editAfil(item)">
                <v-icon>border_color</v-icon></v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip v-if="auth.roles.includes('Configuraciones_ServiciosFarmaceuticos_Afiliados_eliminar')" bottom
            :color="item.eliminado === false ? 'error' : 'success'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon :color="item.eliminado === false ? 'error' : 'success'" v-bind="attrs" v-on="on"
                @click="modalActivoDesactivo(item)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>{{ item.eliminado === false ? 'Eliminar' : 'Activar' }}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- Columna de trazabilidad -->
      <template v-slot:[`item.trazabilidad`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="blue" v-bind="attrs" v-on="on" @click="listarTrazabilidad(item)">
              <v-icon>backup_table</v-icon>
            </v-btn>
          </template>
          <span>Trazabilidad</span>
        </v-tooltip>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- modal para confimar activar o desactivar afiliado -->
    <v-dialog v-model="dialogActivar" max-width="360">
      <v-card>
        <v-toolbar color="primary" class="text-h6" dark>
          {{
        selectedAffiliate.eliminado == false ? '¿Desea eliminar el afiliado?' : '¿Desea activar el afiliado ? '
      }}
        </v-toolbar>
        <div class="d-flex justify-end pa-3">
          <v-btn color="error" text @click="dialogActivar = false"> No </v-btn>
          <v-btn color="success" @click="cambiarEstadoAfiliado(selectedAffiliate, estadoAfiliadoCambiar)">
            Si </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <ModalTrazabilidad :trazabilidadData="trazabilidadData" :trazaDe="trazaDe" />


  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};
import { mapMutations, mapState } from "vuex";
import AfiliadoForm from '@/components/AfiliadoForm.vue'
import ModalTrazabilidad from '@/components/ModalTrazabilidad.vue';

export default {
  name: "Afiliados",
  components: {
    AfiliadoForm,
    ModalTrazabilidad
  },
  data() {
    return {
      cargandoDatos: false,
      itemsPorPagina: 10,
      pagina: 1,
      totalElementos: 0,
      codigoEmpresa: 1,
      iconoEstadoAfiliado: "",
      estadoAfiliadoCambiar: "",
      selectedAffiliate: {},
      dialogActivar: false,
      tipoDocumento: "",
      numDocumento: "",
      tiposDoc: [],
      afiliados: [],
      departamentos: [],
      regimenAfiliados: [],
      aseguradoraItems: [],
      headers: [
        { text: "TIPO DOCUMENTO", value: "tipoDocumento.tipoDocumento", sortable: false },
        { text: "DOCUMENTO", value: "numeroDocumento", sortable: false },
        { text: "NOMBRE", value: "nombreCompleto", sortable: false },
        { text: "CONTACTO", value: "contacto", sortable: false },
        { text: "DIRECCIÓN", value: "direccion", sortable: false },
        // { text: "ASEGURADORA", value: "DES_ASEG", sortable: false },
        { text: "ESTADO", value: "estadoAfiliado", sortable: false, },
        { text: "ACCIONES", value: "acciones", sortable: false },
        { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false },
      ],
      footerProps: {
        "show-first-last-page": true,
        "items-per-page-text": "Ítems por página",
        "items-per-page-options": [10, 20, 30, 40],
        "show-current-page": true,
      },
      trazabilidadData: [],
      trazaDe: '',
      estadoIconos: {
        ACTIVO: 'check_circle',
        INACTIVO: 'cancel',
        SUSPENDIDO: 'person_remove',
        RETIRADO: 'person_off',
        FALLECIDO: 'no_accounts',
        PENDIENTE: 'flaky'
      }
    };
  },
  watch: {
    "itemsPorPagina": function () { this.getAfiliados(); },
    "pagina": function () { this.getAfiliados(); },
    "numDocumento": debounce(function () {
      this.pagina = 1;
      this.getAfiliados();
    }, 500),

    "tipoDocumento": debounce(function () {
      this.pagina = 1;
      this.getAfiliados();
    }, 500),

  },
  created() {
    this.trazabilidad.visible = false;
    this.getTiposDocs();
    this.getDepartamento();
    this.getAseguradoras();
    this.getAfiliados();
  },
  computed: {
    ...mapState(["auth", "formAfiliado", "trazabilidad", "enterprise"]),
  },
  methods: {
    ...mapMutations(["showSuccess", "showError", "addAfil", "editAfil"]),

    getAfiliados() {
      this.afiliados = [];
      this.cargandoDatos = true;
      this.$http.get(`msa-pharmaceutical-services/api/Afiliado/list`, {
        params: {
          tipoDocumento: this.tipoDocumento,
          numeroDocumento: this.numDocumento,
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          empresa: this.enterprise.code
        }
      }).then(({ data }) => {
        data.content.forEach(afiliado => {
          afiliado.contacto = afiliado.numeroCelular == null || afiliado.numeroCelular == 0 ? afiliado.numeroTelefono : afiliado.numeroCelular;
        });
        this.totalElementos = data.totalElements;
        this.afiliados = data.content;
        this.cargandoDatos = false;
      }).catch(() => this.showError("Error al buscar datos"))
    },

    getTiposDocs() {
      this.$http.get("msa-pharmaceutical-services/api/TipoDocumento/tiposdocumentos").then(({ data }) => {
        data.forEach(doc => this.tiposDoc.push({ text: doc.descripcion, value: doc.tipoDocumento }));
      }).catch(err => console.log(err));
    },

    modalActivoDesactivo(dataAfiliado) {
      this.selectedAffiliate = dataAfiliado;
      this.dialogActivar = true;
      dataAfiliado.eliminado === false ? this.estadoAfiliadoCambiar = true : this.estadoAfiliadoCambiar = false;
    },

    cambiarEstadoAfiliado(dataAfiliado, estado) {
      this.$http.put(`msa-pharmaceutical-services/api/Afiliado/estado-afiliado/${dataAfiliado.idAfiliado}`, { eliminado: estado })
        .then(() => {
          this.showSuccess("El estado del afiliado ha sido cambiado correctamente.");
          this.dialogActivar = false;
          this.getAfiliados();
        }).catch(err => console.log(err));
    },

    getDepartamento() {
      this.departamentos = [];
      this.$http.get(`msa-administration/departamento/listaDeDepartamentos`, {
        params: { idPais: 170 }
      }).then(({ data }) => {
        if (data) data.forEach(dep => {
          if (dep.idDepartamento != '00') this.departamentos.push({ text: dep.departamento, value: dep.idDepartamento });
        })
      }).catch(err => console.log(err))
    },

    getAseguradoras() {
      this.$http.get(`msa-pharmaceutical-services/api/DpAsegu/aseguradoras`, { params: { codEmpr: this.codigoEmpresa } })
        .then(({ data }) => {
          const activas = data.filter(aseg => aseg.actEsta == 'A');
          activas.forEach(res => this.aseguradoraItems.push({ text: res.desAseg.toUpperCase(), value: res.codAseg }));
        }).catch(err => console.log(err))
    },

    estadosAfiliado(estado) {
      return this.estadoIconos[estado] || '';
    },

    listarTrazabilidad(afiliado) {
      this.$http.get(`msa-pharmaceutical-services/api/Afiliado/trazabilidad/${afiliado.idAfiliado}`)
        .then(({ data }) => {
          data.forEach(traza => {
            traza.time = this.formatoFechaTrazabilidad(traza.time);
            traza.campo = traza.campo.charAt(0).toUpperCase() + traza.campo.slice(1).replace('_', ' ');
          })
          this.trazabilidad.visible = true;
          this.trazaDe = afiliado.nombreCompleto;
          this.trazabilidadData = data.reverse();
        }).catch(err => console.log(err))
    },

    formatoFechaTrazabilidad(dateTimeString) {
      const fecha = new Date(dateTimeString);
      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
      const formatoHoras = horas % 12 || 12;
      const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
      const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const formatoDia = fecha.getDate().toString().padStart(2, '0');
      const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos}${ampm}`;
      return formatoFechaHora;
    },
  },

}


</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .tablaAfiliados div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaAfiliados div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

::v-deep .tablaAfiliados div {
  --sb-track-color: #d1cfcf;
  --sb-thumb-color: #8b8585;
  --sb-size: 8px;
}

::v-deep .tablaAfiliados div::-webkit-scrollbar {
  width: var(--sb-size)
}

::v-deep .tablaAfiliados div::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 6px;
}

::v-deep .tablaAfiliados div::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 6px;

}
</style>